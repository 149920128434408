<template>
  <div class="aui-wrapper aui-page__not-found">
    <div class="aui-content__wrapper">
      <div class="aui-content">
        <h2 class="title">400</h2>
        <p class="desc" v-html="'抱歉！您访问的页面<em>失联</em>啦...'"></p>
        <div class="btn-bar">
          <el-button @click="$router.go(-1)">上一页</el-button>
          <el-button type="primary" @click="$router.push({ name: 'home' })">首页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
<style lang="less" scoped>
.aui-page__not-found {
  .aui-content {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    min-height: 100vh;
    padding: 15% 50px 50px;
    text-align: center;
    box-sizing: border-box;
    &__wrapper {
      height: 100vh;
      background-color: transparent;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .title {
    margin: 0 0 15px;
    font-size: 10em;
    font-weight: 400;
    color: #606266;
  }
  .desc {
    margin: 0 0 20px;
    font-size: 26px;
    color: #909399;
    > em {
      margin: 0 5px;
      font-style: normal;
      color: #606266;
    }
  }
  .btn-bar .el-button {
    margin: 0 15px;
  }
}
</style>
